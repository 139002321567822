









































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import vSelect from 'vue-select';

import Loader from '@/shared/resources/components/Loader.vue';
import ORMCollection from '@/core/bridge/orm/ORMCollection';

import 'vue-select/dist/vue-select.css';
import ORMModel from '@/core/bridge/orm/ORMModel';

@Component({
  components: {
    Loader,
    vSelect,
  },
})
export default class SearchDropdown extends Vue {
  /**
   * Refs
   */
  @Ref() private searchInputRef!: HTMLInputElement;

  /**
   * Props
   */
  @Prop() private collection!: ORMCollection;
  @Prop({ default: 'Szukaj' }) private placeholder!: string;
  @Prop({ default: false }) private loading!: boolean;

  /**
   * Data
   */
  private selected: any[] = [];
  private searchDelayTimeout?: any | undefined;
  private selectingBlocked: boolean = false;

  /**
   * Get products options
   */
  private get options(): any[] {
    return this.collection.items.map((item: ORMModel) => {
      return {
        ...item,
        label: '',
      };
    });
  }

  /**
   * Get no options label
   */
  private get noOptionsLabel() {
    return this.collection.isInjected && !this.collection.count
      ? 'Nie znaleziono.'
      : 'Wpisz zapytanie, aby wyszukać.';
  }

  private get closeOnSelect(): boolean {
    return !this.selectingBlocked;
  }

  /**
   * Handle select
   */
  private onSelect(data: any) {
    if (this.selectingBlocked) {
      return;
    }

    this.$emit('select', data);
  }

  /**
   * Handle search field input
   */
  private onSearch(searchString: string) {
    this.selectingBlocked = true;

    clearTimeout(this.searchDelayTimeout);
    this.searchDelayTimeout = setTimeout(() => {
      this.$emit('search', searchString);
      this.selectingBlocked = false;
    }, 500);
  }
}
